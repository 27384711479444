import styled, { css } from "styled-components";

const Button = styled.button`
  display: inline-block;
  padding: 8px;
  background-color: var(--active-color);
  border: 1px solid transparent;
  border-radius: 10px;
  color: white;
  outline: none;
  min-width: 100px;
  line-height: normal;
  text-decoration: none;
  text-align: ${(props) => props?.textAlign || "center"};

  font-family: var(--font-family);
  font-weight: 500;
  font-size: calc(var(--font-size) + 1px);

  :active {
    opacity: 0.85;
  }

  span {
    font-size: calc(var(--font-size) + 4px);
  }

  ${(props) =>
    props.secondary &&
    css`
      background-color: transparent;
      color: black;
      border-color: var(--border-color);
    `}
`;

export default Button;
