import styled, { css } from "styled-components";

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  padding: 4px;

  ${(props) =>
    props.selected &&
    css`
      border-color: var(--active-color);
    `}
`;

export default Option;
