import styled from "styled-components"

const Input = styled.input`
	display: block;
	width: 100%;
	padding: 10px 15px;
	background-color: transparent;
	border: 1px solid transparent;
	outline: none;
	font-family: var(--font-family);
	font-weight: 500;
	letter-spacing: 2px;
	font-size: 50px;
	color: var(--active-color);
	text-align: ${(props) => props.align || "left"};

	&::placeholder {
		color: var(--border-color);
	}
`

export default Input
