import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
    ${reset}

    :root {
        --font-family: sans-serif;
        --font-size: 14px;
        --border-color: #e1f5fe;
        --active-color: #00a7ff;
        --active-light-color: #b3e5fc;
    }

    * {
        box-sizing: border-box;
    }

    body {
        font-family: var(--font-family);
        font-size: var(--font-size);
    }
`;

export default GlobalStyle;
