import React, { useMemo, useState } from "react"

import styled, { css } from "styled-components"
import Button from "../Button"
import Input from "../Input"
import MontoSelectable from "./MontoSelectable"
import OperadorSelectable from "./OperadorSelectable"

const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Card = styled.div`
	background: white;
	width: 100%;
	max-width: 90%;
	padding: 30px 15px;
	border-radius: 5px;
	box-shadow: 0 0 30px #eee;
	max-width: 375px;
`

const FormGroup = styled.div`
	margin-bottom: 15px;
`

const FormLabel = styled.label`
	display: inline-block;
	margin-bottom: 10px;
	font-weight: 700;

	${(props) =>
		props.secondary &&
		css`
			font-weight: 400;
		`}
`

const MOBILE_OPERATOR_CODE = {
	claro: "*789*1*[celular]*[monto]*1*3724%23",
	movistar: "*690*1*[monto]*[celular]*4444%23",
	entel: "*690*8*[monto]*[celular]*4444%23",
	bitel: "*690*9*[monto]*[celular]*4444%23",
}

const Recargar = () => {
	const [mobileOperator, setMobileOperator] = useState("claro")
	const [amountSelected, setAmountSelected] = useState(3)
	const [amount, setAmount] = useState("")
	const [phone, setPhone] = useState("")

	const rechargeCode = useMemo(() => {
		let mobileOperatorCode = MOBILE_OPERATOR_CODE?.[mobileOperator] || ""

		mobileOperatorCode = String(mobileOperatorCode).replace("[celular]", phone)
		mobileOperatorCode = String(mobileOperatorCode).replace("[monto]", amount)
		return `tel:${mobileOperatorCode}`
	}, [mobileOperator, amount, phone])

	const reset = () => {
		setMobileOperator("claro")
		setAmountSelected(3)
		setPhone("")
	}

	const seeLastRecharge = () => {
		const lastRechargeCodes = {
			claro: "*789*11*1*3724%23",
			movistar: "*690*4*4444%23",
			entel: "*690*4*4444%23",
			bitel: "*690*4*4444%23",
		}

		const lastRechargeCode = lastRechargeCodes[mobileOperator]
		window.location.href = `tel:${lastRechargeCode}`
	}

	const seeLastClientRecharge = () => {
		if (String(phone).trim().length !== 9) {
			return alert("Ingresa un número de celular correcto")
		}

		window.location.href = `tel:*789*11*7*${phone}*3724%23`
	}

	const seeBalance = () => {
		const balanceCodes = {
			claro: "*789*11*2*3724%23",
			movistar: "*690*3*4444%23",
			entel: "*690*3*4444%23",
			bitel: "*690*3*4444%23",
		}

		const balanceCode = balanceCodes[mobileOperator]
		window.location.href = `tel:${balanceCode}`
	}

	const handleOnRecharge = () => {
		fetch("https://wasap.osso.dev/send", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				to: "51974228251,51947242116",
				message: `Recarga de ${amount} soles a ${phone} con ${mobileOperator}`,
				secret: "s3cr3t1t@",
			}),
		})
	}

	return (
		<Wrapper>
			<Card>
				<FormGroup>
					<FormLabel htmlFor="">Seleccione operador:</FormLabel>
					<OperadorSelectable defaultValue={mobileOperator} onChange={setMobileOperator} />
				</FormGroup>

				<FormGroup>
					<FormLabel htmlFor="">Monto:</FormLabel>
					<MontoSelectable selected={amountSelected} onSelected={setAmountSelected} onChange={setAmount} />
				</FormGroup>

				<FormGroup>
					<FormLabel htmlFor="">Celular:</FormLabel>
					<Input
						type="number"
						placeholder="9XXXXXXXX"
						align="center"
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</FormGroup>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr",
						columnGap: 15,
						rowGap: 15,
					}}
				>
					<Button style={{ borderColor: "var(--active-color)" }} type="button" secondary onClick={reset}>
						Nuevo
					</Button>
					<Button as="a" href={rechargeCode} onClick={handleOnRecharge}>
						Recargar S/ <span>{amount}</span>
					</Button>
					<Button secondary onClick={seeLastRecharge}>
						Última recarga
					</Button>
					{mobileOperator === "claro" && (
						<Button secondary onClick={seeLastClientRecharge}>
							Última recarga (cliente)
						</Button>
					)}
					<Button secondary onClick={seeBalance}>
						Consultar saldo
					</Button>
				</div>
			</Card>
		</Wrapper>
	)
}

export default Recargar
