import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import Input from "../Input"
import Option from "../SelectableOptions/Option"
import { useRef } from "react"

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 56px 56px;
	column-gap: 15px;
	row-gap: 10px;
	margin-bottom: 10px;
`

export const options = [3, 5, 7, 10]

const MontoSelectable = ({ selected, onSelected, onChange }) => {
	const anotherAmountInputRef = useRef()
	const [optSelected, setOptSelected] = useState(selected)
	const [otherAmount, setOtherAmount] = useState("")

	const handleSelect = (value) => {
		setOptSelected(value)
		onSelected(value)
	}

	const handleAnotherAmount = () => {
		handleSelect(null)
		anotherAmountInputRef.current.focus()
	}

	const isOther = useMemo(() => optSelected === null, [optSelected])

	useEffect(() => {
		onChange(optSelected || otherAmount)
	}, [optSelected, otherAmount])

	useEffect(() => {
		setOptSelected(selected)
		setOtherAmount("")
	}, [selected])

	return (
		<Wrapper>
			{options.map((value) => (
				<Option key={value} selected={optSelected === value} onClick={() => handleSelect(value)}>
					<span
						style={{
							fontSize: 14,
							color: "var(--active-color)",
							marginRight: 5,
						}}
					>
						S/
					</span>
					<span style={{ fontSize: 24, fontWeight: 300 }}>{value}</span>
				</Option>
			))}
			<Option selected={isOther} onClick={handleAnotherAmount} style={{ gridColumn: "1 / 3" }}>
				<span style={{ fontSize: 14, textAlign: "center" }}>Otro monto</span>
			</Option>

			<div style={{ gridColumn: "3 / 5", opacity: isOther ? 1 : 0, pointerEvents: isOther ? "auto" : "none" }}>
				<Input
					ref={anotherAmountInputRef}
					style={{
						padding: 0,
						height: "100%",
					}}
					type="number"
					placeholder="X.XX"
					align="right"
					value={otherAmount}
					onChange={(e) => setOtherAmount(e.target.value)}
				/>
			</div>
		</Wrapper>
	)
}

export default MontoSelectable
