import GlobalStyle from "./components/GlobalStyle";
import Recargar from "./components/Recargar";

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Recargar />
    </>
  );
};

export default App;
