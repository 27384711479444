import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Option from "../SelectableOptions/Option";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 15px;
  margin-bottom: 10px;
`;

export const options = [
  {
    value: "claro",
    label: "Claro",
    image: "/logos/claro.png",
  },
  {
    value: "movistar",
    label: "Movistar",
    image: "/logos/movistar.png",
  },
  {
    value: "entel",
    label: "Entel",
    image: "/logos/entel.png",
  },
  {
    value: "bitel",
    label: "Bitel",
    image: "/logos/bitel.png",
  },
];

const OperadorSelectable = ({ defaultValue, onChange }) => {
  const [optSelected, setOptSelected] = useState(defaultValue);

  const handleSelect = (value) => {
    setOptSelected(value);
    onChange(value);
  };

  useEffect(() => {
    setOptSelected(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Wrapper>
        {options.map(({ value, label, image }) => (
          <Option
            key={value}
            selected={optSelected === value}
            onClick={() => handleSelect(value)}
          >
            <img src={image} alt={label} />
          </Option>
        ))}
      </Wrapper>
    </>
  );
};

export default OperadorSelectable;
